import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'

const ZoneDeReconfort = () => {
  const metaTitle = 'Zone de réconfort | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Zone de réconfort"
      />
      <div className="container content pb-12">
        <div>

          {/* ZONE DE RÉCONFORT */}
          <div>
            <h2>Zone de réconfort</h2>
            <p>Présence d’organismes communautaires pour créer un Safe space pour supporter les festivalier.ère.s durant leur séjour.</p>
            <br/>
            <div className='ul-container'>
              <ul>
                <li>Mercredi 17 juillet de 17h à 1h</li>
                <li>Jeudi 18 juillet de 12h à 3h</li>
                <li>Vendredi 19 juillet de 12h à 3h</li>
                <li>Samedi 20 juillet de 12h à 3h</li>
                <li>Dimanche 21 juillet : 12h à 18h</li>
              </ul>
            </div>
          </div>

          {/* <><p><BigButton href="/infos/carte#info_content" text="Voir sur la carte"></BigButton></p></> */}

          <div>
            <h2>Présence de l'infirmerie</h2>
            <h3>Sabsa</h3>
            <p>La Coopérative de solidarité déploiera son unité mobile d’analyse des drogues. Trois technologies seront utilisées dans l’unité mobile. Une fois les résultats connus, des intervenants de SABSA fourniront des conseils aux différents usagers, toujours dans l'objectif d'assurer une consommation sécuritaire.</p>
            <div className="mt-3">
              <a href="https://www.sabsa.ca/fr/" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] pt-2 invert'}
                              src={'../../images/partenaires/sabsa.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo S.A.B.S.A"/>
              </a>
            </div>

            <h3>Vision d’Espoir de Sobriété</h3>
            <p>Cet organisme offre des services d’accueil, d’information, d’orientation et d’accompagnement pour les personnes ayant un trouble d'usage de substances en voie de réadaptation.</p>
            <div className="mt-3">
              <a href="https://www.visiondespoirdesobriete.com/" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] invert'}
                              src={'../../images/partenaires/visiondespoir.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo Vision d'Espoir"/>
              </a>
            </div>

            <h3>CALACS</h3>
            <p>CALACS sera sur place pour prévenir les violences à caractère sexuel et pour accompagner les victimes et leurs proches, tout en faisant de la sensibilisation du public pour enrayer toutes les formes de violences sexuelles.</p>
            <div className="mt-3">
              <a href="http://www.rqcalacs.qc.ca/" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] invert'}
                              src={'../../images/partenaires/rqcalacs.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo C.A.L.A.C.S"/>
              </a>
            </div>

            <h3>CTAQ</h3>
            <p>La Coopérative des techniciens ambulanciers du Québec sera présente sur tous les sites pour assurer une aide médicale rapide, professionnelle et bienveillante et ce, tout au long du festival.</p>
            <div className="mt-3">
              <a href="https://www.ctaq.com/en/home/" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] invert'}
                              src={'../../images/partenaires/ctaq.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo CTAQ"/>
              </a>
            </div>

            <h3>Ressource Genesis</h3>
            <p>La Coopérative des techniciens ambulanciers du Québec sera présente sur tous les sites pour assurer une aide médicale rapide, professionnelle et bienveillante et ce, tout au long du festival.</p>
            <div className="mt-3">
              <a href="https://www.facebook.com/ressourcegenesis" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] invert'}
                              src={'../../images/partenaires/ressource_genesis.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo Ressource Genesis"/>
              </a>
            </div>

          </div>
        </div>
      </div>
    </Wolflayout>
  )
}

export default ZoneDeReconfort
